import React, { useRef, useEffect, useState } from "react";
import { useStatus } from "../../statusContext";
import "semantic-ui-css/semantic.min.css";
import KeycloakUserService from "../login/KeyCloakService";
import { Form, Grid, Input, Dropdown, Table, Image } from "semantic-ui-react";
import delete_icon from "../../images/delete.png";
import "../../css/index.css";
import "../../css/lexx.css";
import { Oval } from "react-loader-spinner";

const options = [
  { key: 1, text: "Select" },
  { key: 2, text: "Form Filled", value: "Form Filled" },
  { key: 3, text: "Aborted", value: "Aborted" },
  { key: 4, text: "Processing", value: "Processing" },
  { key: 5, text: "Not a Resume", value: "Not a Resume" },
  { key: 6, text: "Error", value: "Error" },
  { key: 7, text: "Assessed", value: "Assessed" },
  { key: 8, text: "Email Sent", value: "Email Sent" },
  { key: 9, text: "Documents Uploaded", value: "Documents Uploaded" },
  { key: 10, text: "Employment Reference Generated", value: "Employment Reference Generated" },
  { key: 11, text: "Employment Reference Sent", value: "Employment Reference Sent" },
  { key: 12, text: "Employment Generation Cancelled", value: "Employment Generation Cancelled" },
  { key: 13, text: "Employment Reference Uploaded", value: "Employment Reference Uploaded" },
  { key: 14, text: "Validation Successful", value: "Validation Successful" },
  { key: 15, text: "Validation Failed", value: "Validation Failed" },
  { key: 16, text: "Validation Failed Email Sent", value: "Validation Failed Email Sent" },
  { key: 17, text: "Failed Documents Uploaded", value: "Failed Documents Uploaded" },
  { key: 18, text: "Washington Accord", value: "Washington Accord" },
  { key: 19, text: "Dublin Accord", value: "Dublin Accord" },
  { key: 20, text: "Sydney Accord", value: "Sydney Accord" },
  { key: 21, text: "CDR", value: "CDR" },
  { key: 22, text: "PSA", value: "PSA" },
  { key: 23, text: "JRP", value: "JRP" },
  { key: 24, text: "TSS", value: "TSS" },
  { key: 25, text: "OSAP", value: "OSAP" },
  { key: 26, text: "MSA", value: "MSA" },
  { key: 27, text: "MPA", value: "MPA" },
  { key: 28, text: "Pathway 1", value: "Pathway 1" },
  { key: 29, text: "Pathway 2", value: "Pathway 2" },
  { key: 30, text: "TG", value: "TG" },
  { key: 31, text: "Skills", value: "Skills" },
  { key: 32, text: "PAS", value: "PAS" },
  { key: 33, text: "RPL", value: "RPL" },
  { key: 34, text: "ACS", value: "ACS" },
  { key: 35, text: "Engineers Australia", value: "Engineers Australia" },
  { key: 36, text: "VETASSESS Trade", value: "VETASSESS Trade" },
  { key: 37, text: " VETASSESS Professional", value: " VETASSESS Professional" },
  { key: 38, text: "TRA", value: "TRA" },
];


const AWAITING_REVIEW = {
  color: "white",
  backgroundColor: "#0971ce",
  borderRadius: "5px",
  padding: "5px",
};

function Documents(props) {
  const [fileStore, setFileStore] = useState([]);
  const { docName, dashStatus } = useStatus();
  const [checklistData, setChecklistData] = useState({ data: null });
  const [disableChecklist, setDisableChecklist] = useState(true);
  const [disablegenEmp, setDisablegenEmp] = useState(true);
  const [disableCollect, setDisableCollect] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const wsRef = useRef(null);

  props.setDisableChecklistbtn(disableChecklist);
  props.setDisablegenEmpbtn(disablegenEmp);
  props.setDisableCollectEmpbtn(disableCollect);

  const updateItemState = (item) => {
    const newData = item.applicant_id === checklistData.data?.applicant_id ? null : item;
    setChecklistData({ data: newData });
    localStorage.setItem("data", JSON.stringify(newData));

    setDisableChecklist(
      newData === null || item.status !== "Assessed" && !(item.status === "Email Sent" && ["TG", "Skills", "PAS", "RPL"].includes(item.recommended_pathway))
    );
    setDisablegenEmp(
      newData === null || item.status !== "Documents Uploaded" || item.recommended_pathway === "TG"
    );
    setDisableCollect(
      newData === null || !((item.recommended_pathway === "TG" && item.status === "Documents Uploaded") ||
      item.status === "Employment Reference Uploaded" || item.status === "Failed Documents Uploaded")
    );
  };


  const handleDeleted = async (applicant_id) => {
    const confirmed = window.confirm("Are you sure you want to delete?");
    if (!confirmed) return;

    const token = await KeycloakUserService.getToken();
    if (!token) { alert("You need to be logged in."); return; }

    const url = `https://api.xhorizons-uat.lexxtechnologies.com/delete/${applicant_id}`;
    try {
      const response = await fetch(url, { method: "DELETE", headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` } });
      if (response.status === 200) { alert("Deletion successful"); connectWebSocket(); }
      else if (response.status === 401) { alert("Unauthorized."); }
      else if (response.status === 404) { alert("Applicant not found.");}
      else { alert(`Deletion failed. HTTP Status: ${response.status}`); }
    } catch (error) {
      console.error("Error deleting:", error);
      alert("An error occurred.");
    }
  };

  const connectWebSocket = () => {
    const newUrl = `wss://api.xhorizons-uat.lexxtechnologies.com/dashboard?filter=${filterValue}&search=${searchTerm}`;
    if (wsRef.current) wsRef.current.close();
    const ws = new WebSocket(newUrl);
    wsRef.current = ws;
    ws.onopen = () => console.log('WebSocket opened.');
    ws.onmessage = (event) => { console.log('Message received:', event.data); setFileStore(JSON.parse(event.data)); };
    ws.onclose = () => console.log('WebSocket closed.');
  };

  useEffect(() => { connectWebSocket(); }, [searchTerm, filterValue]);

  const renderTableRows = () => {
    if (!fileStore.dashboard) {
      return (<Table.Row><Table.Cell style={{ paddingLeft: "200px" }}><Oval height={50} width={50} color="#0971ce" visible={true} ariaLabel="oval-loading" secondaryColor="gray" strokeWidth={2} /></Table.Cell></Table.Row>);
    }

    return fileStore.dashboard.map((item, index) => (
      <Table.Row key={index}>
        <Table.Cell>
          <input type="checkbox" checked={checklistData.data?.applicant_id === item.applicant_id} onChange={() => updateItemState(item)} style={{ marginRight: "10px" }} />
          {item.applicant_name}
        </Table.Cell>
        <Table.Cell><div className="table-row-item">{item.assessed_on}</div></Table.Cell>
        <Table.Cell><div className="table-row-item">{item.recommended_occupation}</div></Table.Cell>
        <Table.Cell><div className="table-row-item">{item.assessed_for}</div></Table.Cell>
        <Table.Cell><div className="table-row-item">{item.recommended_pathway || ""}</div></Table.Cell>
        <Table.Cell><div className="table-row-item">{item.status}</div></Table.Cell>
        <Table.Cell>
          <div className="table-row-item" onClick={() => handleDeleted(item.applicant_id)}>
            <Image src={delete_icon} avatar />
          </div>
        </Table.Cell>
      </Table.Row>
    ));
  };

  return (
    <>
      <div className="session-summary documentlist document-table">
        <div className="search-dropdown">
          <Form>
            <Grid padded>
              <Grid.Row stretched>
                <Grid.Column className="searchsolution" width={13}>
                  <Form.Field>
                    <Input icon="search" iconPosition="left" placeholder="SEARCH" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column className="searchsolutionstatus">
                  <Dropdown className="statusdropdown" clearable options={options} selection button placeholder="Status" value={filterValue} onChange={(e, { value }) => setFilterValue(value)} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </div>

        <div style={{ borderBottom: "1px solid #BDBDBD", display: "flex", flexDirection: "row", fontWeight: "bold", marginBottom: "20px", width: "100%", cursor: "pointer", overflowX: "auto" }}>
          <div style={{ padding: "5px" }}><span>My Resumes</span></div>
          <div style={{ padding: "5px", paddingLeft: "30px" }}><span>All Resumes</span></div>
        </div>
      </div>

      <div className="tableWrap">
        <Table celled unstackable style={{ border: "none" }} className="table-wrapper">
          <Table.Header>
            <Table.Row style={{ position: "sticky", top: "0", zIndex: "1" }}>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell className="table-header">Assessed On</Table.HeaderCell>
              <Table.HeaderCell className="table-header">Assessed Occupation</Table.HeaderCell>
              <Table.HeaderCell className="table-header">Assessed for</Table.HeaderCell>
              <Table.HeaderCell className="table-header">Recommended Pathway</Table.HeaderCell>
              <Table.HeaderCell className="table-header">Status</Table.HeaderCell>
              <Table.HeaderCell className="table-header">Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {dashStatus && (
              <Table.Row key={-1}>
                <Table.Cell>
                  <input type="checkbox" />
                  <a href={`/resume/${docName}`}>{docName}</a>
                </Table.Cell>
                <Table.Cell><span style={AWAITING_REVIEW}>In Process</span></Table.Cell>
                <Table.Cell></Table.Cell> <Table.Cell></Table.Cell> <Table.Cell></Table.Cell> <Table.Cell></Table.Cell> <Table.Cell></Table.Cell>
              </Table.Row>
            )}
            {renderTableRows()}
          </Table.Body>
        </Table>
      </div>
    </>
  );
}

export default Documents;