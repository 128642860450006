import React, { useState, useEffect } from "react";
import { Table, Modal } from "semantic-ui-react";
import { Link , useNavigate} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import "../Generate/gen.css";


const ValidationField = () => {
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [validationStatus, setValidationStatus] = useState(null);
  const data = JSON.parse(localStorage.getItem("data"));
  const applicant_id = data ? data.applicant_id : null;
  const skill = data ? data.applicant_name : null;
  const navigate = useNavigate();


  // Sample JSON data
  const jsonData = JSON.parse(sessionStorage.getItem("validationData"));

  useEffect(() => {
    if (successModalOpen && validationStatus === "Validation Successfull") {
      handleDownload();
    }
  }, [successModalOpen, validationStatus]);

  const handleNextButtonClick = () => {
    // Make GET request to API endpoint
    fetch(`https://api.xhorizons-uat.lexxtechnologies.com/status/${applicant_id}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "Validation Successful") {
          setValidationStatus(data.status);
          setSuccessModalOpen(true);
        } else {
          setErrorModalOpen(true);
        }
      })
      .catch((error) => {
        console.error("Error fetching validation status:", error);
        // Handle error
      });
  };

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
  };

  const handleCloseErrorModal = () => {
    setErrorModalOpen(false);
  };

  const handleDownload = () => {
  
    fetch(`https://api.xhorizons-uat.lexxtechnologies.com/download/${applicant_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/zip",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "validation_report.zip");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        navigate("/"); // Navigate to home page after download starts
      })
      .catch((error) => console.error("Error downloading file:", error));
  };

  const handleEmailSent = () => {
  
    fetch(`https://api.xhorizons-uat.lexxtechnologies.com/sendagain/${applicant_id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // Include any necessary data in the request body
      }),
    })
    .then((response) => {
      if (response.ok) {
        // Handle success
        console.log("Email sent successfully");
        navigate("/emailsent"); // Navigate to EmailSent screen
      } else {
        // Handle error
        console.error("Failed to send email");
      }
    })
    .catch((error) => {
      console.error("Error sending email:", error);
    });
  };

  return (
    <>
      <div className="gen-field">
        <div className="gen-header">Document Validation Report
        <h1>{skill}</h1>
        </div>
        <div className="tableWrap">
          <Table
            celled
            unstackable
            style={{ border: "none" }}
            className="table-wrapper"
          >
            {/* Table header */}
            <Table.Header>
              <Table.Row style={{ position: "sticky", top: "0", zIndex: "1" }}>
                <Table.HeaderCell>File Name</Table.HeaderCell>
                <Table.HeaderCell className="table-header">Type</Table.HeaderCell>
                <Table.HeaderCell className="table-header">Detected Type</Table.HeaderCell>
                <Table.HeaderCell className="table-header">Name in Document</Table.HeaderCell>
                <Table.HeaderCell className="table-header">Document Match</Table.HeaderCell>
                <Table.HeaderCell className="table-header">Name Match</Table.HeaderCell>
                <Table.HeaderCell className="table-header">Clear</Table.HeaderCell>
                <Table.HeaderCell className="table-header">Colorful</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {/* Table body */}
            <Table.Body>
              {Object.entries(jsonData).map(([fileName, fileData]) => (
                <Table.Row key={fileName}>
                  <Table.Cell>
                    <div className="table-row-item">{fileName}</div>
                  </Table.Cell>
                  <Table.Cell>
                    <div className="table-row-item">{fileData.Type}</div>
                  </Table.Cell>
                  <Table.Cell>
                    <div className="table-row-item">{fileData["Detected Type"]}</div>
                  </Table.Cell>
                  <Table.Cell>
                    <div className="table-row-item">{fileData["Name in Document"]}</div>
                  </Table.Cell>
                  <Table.Cell>
                  <div className="table-row-item">{fileData["Document Match"] ? "Yes" : "No"}</div>
                  </Table.Cell>
                  <Table.Cell>
                  <div className="table-row-item">{fileData["Name Match"] ? "Yes" : "No"}</div>
                  </Table.Cell>
                  <Table.Cell>
                    <div className="table-row-item">{fileData.Clear ? "Yes" : "No"}</div>
                  </Table.Cell>
                  <Table.Cell>
                    <div className="table-row-item">{fileData.Colorful ? "Yes" : "No"}</div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
        {/* Buttons */}
        <div className="button-previous-form">
          <Link to={"/"}>
            <button className="FormSubmitbt" type="button">
              Back
            </button>
          </Link>
          <button className="FormSubmitbt" type="button" onClick={handleNextButtonClick}>
            Next
          </button>
        </div>
      </div>
      {/* Success Popup modal */}
      <Modal open={successModalOpen} onClose={handleCloseSuccessModal} size="mini">
        <Modal.Content style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <FontAwesomeIcon icon={faCheckCircle} style={{ color: '#0971ce', height: '50px' }} />
        </Modal.Content>
        <Modal.Content>
          <h1 style={{ margin: 0 }}>All Documents validated.</h1>
          <p style={{ margin: '20px 0', textAlign: 'center' }}>Please Download the Zip File.</p>
          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
            <button className="FormSubmitbtenc" onClick={handleCloseSuccessModal}>
              Close
            </button>
            <button className="FormSubmitbtenn" onClick={handleDownload}>
              Download
            </button>
          </div>
        </Modal.Content>
      </Modal>
      {/* Error Popup modal */}
      <Modal open={errorModalOpen} onClose={handleCloseErrorModal} size="mini">
        <Modal.Content style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <FontAwesomeIcon icon={faExclamationCircle} style={{ color: 'red', height: '50px' }} />
        </Modal.Content>
        <Modal.Content>
          <h1 style={{ margin: 0 }}>Validation Not Successful</h1>
          <p style={{ margin: '20px 0', textAlign: 'center' }}>Please check your documents and try again.</p>
          <div style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
            <button className="FormSubmitbtenc" onClick={handleCloseErrorModal}>
              Close
            </button>
            <button className="FormSubmitbtenn" onClick={handleEmailSent}>
              <span> Resend Email</span>
             
            </button>
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default ValidationField;
