/***************************
 * FormField: This component shows the form and API integration.
 * Contributors: Abhishek Kumar Yadav
 ***************************/

import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./../../css/formField.css";
import { SyncLoader } from "react-spinners";
import KeycloakUserService from '../login/KeyCloakService';


const FormFiled = () => {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [personalDetail, setPersonalDetail] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    DateOfBirth: "",
    Country: "",
    Consent:true,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setPersonalDetail((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : formatValue(name, value),
    }));

  };

  const formatValue = (name, value) => {
    if (name === "FirstName" || name === "LastName") {
      return formatName(value);
    } else if (name === "DateOfBirth") {
      return formatDate(value);
    } else if (name === "Country") {
      return value.trim().replace(/\b\w/g, (c) => c.toUpperCase());
    }
    return value;
  };

  const formatName = (value) => {
    const isNumber = !isNaN(parseFloat(value)) && isFinite(value);
    if (isNumber) return value;
    return value.split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const formatDate = (value) => {
    const datePattern = /^\d{4}-\d{2}-\d{2}$/;
    if (value.length > 0 && !datePattern.test(value)) return "";

    const parts = value.split("-");
    if (parts.length === 3 && parts[0].length > 4) {
      const trimmedYear = parts[0].slice(0, 4);
      return `${trimmedYear}-${parts[1] || "01"}-${parts[2] || "01"}`;
    }
    return value.trim().replace(/\b\w/g, (c) => c.toUpperCase());
  };

  const validateForm = () => {
    const today = new Date();
    const dob = new Date(personalDetail.DateOfBirth);
    const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

    if (dob > eighteenYearsAgo) return "The Applicant does not qualify for minimum age requirements.";
    
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(personalDetail.Email)) return "Please provide a valid email.";
    
    const mandatoryFields = ["DateOfBirth", "Email", "LastName", "Country"];
    if (mandatoryFields.some(field => !personalDetail[field])) {
      return "Please fill up the mandatory information!";
    }

    if (!document.getElementById("check").checked) {
      return "Please give your consent to use your personal information!";
    }

    const age = Math.abs(new Date(Date.now() - dob.getTime()).getUTCFullYear() - 1970);
    if (age > 45) return "Not eligible for Immigration. Age greater than 45 years.";

    return null;
  };


  const submitFormData = async () => {
    const validationError = validateForm();
    if (validationError) {
      alert(validationError);
      return;
    }
    const data = { ...personalDetail };
    setLoading(true);
  
    try {
      // Retrieve the Keycloak token using KeycloakUserService
      const token = KeycloakUserService.getToken();
  
      const response = await fetch(" https://api.xhorizons-uat.lexxtechnologies.com/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`, // Add the Keycloak token to the Authorization header
        },
        body: JSON.stringify(data),
      });
  
      const json = await response.json();
      setLoading(false);
  
      // Standardized response handling
      if (json.applicant_id) {
        navigate("/uploadfile", { state: { applicant_id: json.applicant_id } });
      } else if (json.detail) {
        alert(json.detail);
      } else {
        alert("An error occurred. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred. Please try again.");
      setLoading(false);
    }
  };

  return (
    <>
      <center>
        <SyncLoader className={`${!isLoading ? "hidden" : "midd"}`} color="#0971ce" />
      </center>
      <div className={`${isLoading ? "hidden" : "form_body"}`}>
        <div className="h-context">
          <h3 className="pt-5 form_heading">APPLICANT ENTRY FORM</h3>
          <ul>
            <li style={{ color: "#0971ce" }}>
              Please note that fields marked with an asterisk (*) are mandatory.
            </li>
          </ul>
        </div>
        <form className="pb-10">
          <div>
            <h3 className="heading-form personal_details mb-5">PERSONAL DETAILS</h3>
            <div className="filed-area">
              <div className="first-name personal_details_inputfields">
                <label>First Name</label>
                <input
                  onChange={handleChange}
                  value={personalDetail.FirstName}
                  type="text"
                  name="FirstName"
                />
              </div>
              <div className="last-name personal_details_inputfields">
                <label>Last Name <span style={{ color: "red", fontSize: "13px" }}>*</span></label>
                <input
                  onChange={handleChange}
                  value={personalDetail.LastName}
                  type="text"
                  name="LastName"
                />
              </div>
              <div className="personal_details_inputfields">
                <label>Date of Birth <span style={{ color: "red", fontSize: "13px" }}>*</span></label>
                <input
                  onChange={handleChange}
                  value={personalDetail.DateOfBirth}
                  name="DateOfBirth"
                  type="date"
                />
              </div>
            </div>
            <div className="form-filed-area-1">
              <div className="personal_details_inputfields">
                <label>Country <span style={{ color: "red", fontSize: "13px" }}>*</span></label>
                <input
                  onChange={handleChange}
                  value={personalDetail.Country}
                  type="text"
                  name="Country"
                />
              </div>
              <div className="personal_details_inputfields">
                <label>Email ID <span style={{ color: "red", fontSize: "13px" }}>*</span></label>
                <input
                  onChange={handleChange}
                  value={personalDetail.Email}
                  type="email"
                  name="Email"
                />
              </div>
            </div>
            <div className="checkbox-consinment">
              <input
                type="checkbox"
                id="check"
                value={personalDetail.Consent}
                style={{ marginRight: "10px" }}
              />
              <label>
                I agree to use my personal details by eMigration Advisor for Immigration process.
              </label>
            </div>
          </div>
          <div className="button-previous-form">
            <Link to={"/"}>
              <button className="FormSubmitbt" type="button">Back</button>
            </Link>
            <button
              onClick={submitFormData}
              className="FormSubmitBtn"
              type="button"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default FormFiled;