// StatusContext.js
import React, { createContext, useContext, useState } from "react";

const StatusContext = createContext();

export const useStatus = () => {
  return useContext(StatusContext);
};

export const StatusProvider = ({ children }) => {
  const [status, setStatus] = useState(null);
  const [result, setResult] = useState(null);
  const [docName, setDocName] = useState(null);
  const [dashStatus, setDashStatus] = useState(null);
  const [docID, setDocID] = useState(null);
  const [pathway, setPathway] = useState(null);

  const resetPathway = () => {
    setPathway(() => null);
  };

  return (
    <StatusContext.Provider
      value={{
        status,
        setStatus,
        result,
        setResult,
        docName,
        setDocName,
        dashStatus,
        setDashStatus,
        docID,
        setDocID,
        pathway,
        setPathway,
        resetPathway,
      }}
    >
      {children}
    </StatusContext.Provider>
  );
};